import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-products-icon',
    standalone: true,
    imports: [],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M5.62488 5.23877V5.80127H4.13453C3.41487 5.80127 2.81135 6.3446 2.73601 7.06031L1.78864 16.0603C1.70124 16.8906 2.35227 17.6138 3.18716 17.6138H14.8127C15.6476 17.6138 16.2986 16.8906 16.2112 16.0603L15.2639 7.06031C15.1885 6.3446 14.585 5.80127 13.8653 5.80127H12.3749V5.23877C12.3749 3.37481 10.8638 1.86377 8.99988 1.86377C7.13592 1.86377 5.62488 3.37481 5.62488 5.23877ZM8.99988 2.98877C7.75724 2.98877 6.74988 3.99613 6.74988 5.23877V5.80127H11.2499V5.23877C11.2499 3.99613 10.2425 2.98877 8.99988 2.98877ZM6.74988 9.17627C6.74988 10.4189 7.75724 11.4263 8.99988 11.4263C10.2425 11.4263 11.2499 10.4189 11.2499 9.17627V8.61377C11.2499 8.30311 11.5017 8.05127 11.8124 8.05127C12.123 8.05127 12.3749 8.30311 12.3749 8.61377V9.17627C12.3749 11.0402 10.8638 12.5513 8.99988 12.5513C7.13592 12.5513 5.62488 11.0402 5.62488 9.17627V8.61377C5.62488 8.30311 5.87672 8.05127 6.18738 8.05127C6.49804 8.05127 6.74988 8.30311 6.74988 8.61377V9.17627Z"
                fill-rule="evenodd" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsIconComponent {}
